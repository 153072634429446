@media (min-width: 425px) and (max-width: 1024px){
    .dashboard .panel .header .btn{ margin-bottom: 10px; }
    .dashboard .multiGraph{ flex-direction: column; 
        & .panel{ width: unset; margin-right: 0 !important; }
    }
    .empresas-nova .panel .panelBody .multInput{
        &.txCred div{ width: 100%; }
    }
    .debitos-import{
        & .panel .multiInput{ flex-direction: column; 
            & div{ margin-bottom: 14px; }
            & div, & div label{ display: block !important; }
            & select{ width: 100%; }
        }
    }
    .pagamentos{
        & .filtros .panel{ flex-direction: column; align-items: center;
            & input{ margin-right: 0 !important; }
            & p{ margin: 20px 0 0; }
        }
    }
    .pagamentos .panel.pagto, .empresas .panel{ overflow-x: scroll; padding-left: 0; }
}

@media (max-width: 570px){
    .sideMenuBar{ display: none; }
    .body{ margin-left: 0; }
    .dashboard {
        & .panel .header{ flex-direction: column; text-align: center;
            & .btn{ margin-bottom: 10px; }
        }
        & .faturamento{ margin-left: unset !important; }
        & .multiGraph{ flex-direction: column; 
            & .panel{ width: unset; margin-right: 0 !important; }
        }
    }
    .empresas{
        & .tituloButton{ flex-direction: column; }
        & .detalhes .info { margin-left: 0; flex-direction: column;
            & p{ width: 100%; text-align: center; }
        }
    }
    .empresas-nova{
        & .acoes{ flex-direction: column; 
            & a, button{ width: 100% !important; margin-left: unset !important; }
            & a{ margin-bottom: 20px; }
        }
    }
    .empresas-usuarios{
        & .acoes{
            a{ width: 100%; }
        }
    }
    .empresas-usuarios-novo{
        & .panel .multInput{ flex-direction: column; 
            & div{ margin-bottom: 14px; width: 100% !important; }
            & input{ margin-right: 0 !important; }
            & select{ width: 100%; }
        }
        & .acoes{ flex-direction: column; 
            button{ width: 100% !important; margin-left: 0 !important; 
                &:first-child{ margin-bottom: 10px; }
            }
        }
    }
    .clientes{ 
        & .filtros .panel{ flex-direction: column;
            & select{ width: unset; margin-right: 0; }
            & button{ width: 100%; }
        }
        & .detalhes .info p{ width: 100%; text-align: center; }
    }
    .debitos {
        & .filtros .panel{ flex-direction: column;
            & select{ width: unset !important; margin-right: 0; }
            & button{ width: 100%; }
        }
    }
    .debitos, .pagamentos, .empresas-usuarios{
        & .tituloButton{ flex-direction: column; }
    }
    .debitos-import{
        & .panel .multiInput{ flex-direction: column; 
            & div{ display: block !important; }
            & button{ min-width: 200px !important; margin-bottom: 20px; margin-right: 0 !important; }
            & select{ margin-right: 0 !important; width: 100%; }
        }
        & .acoes{ flex-direction: column; 
            & a{ margin-bottom: 10px; }
            & a, button{ width: 100%; }
        }
    }
    .pagamentos{
        & .filtros .panel{ flex-direction: column; 
            & input{ width: unset; margin-right: 0 !important; }
            & p{ text-align: center; margin: 20px 0 0; }
            & button{ width: 100%; }
        }
        & .infoPag{ flex-direction: column; margin-left: 0;
            & p{ width: 100%; text-align: center; }
        }
    }

    .clientes .detalhes .panel, .debitos .panel.dbts, .empresas .panel, .empresas-usuarios .panel{ overflow-x: scroll; padding-left: 0; }
    .footer{ padding: 2.5px 0; }
}

@media (max-width: 768px){
    .empresas-nova .panelBody .multInput{ flex-direction: column;
        & div{ width: 100% !important; margin-bottom: 24px; 
            & input{ margin-right: 0 !important; }
            & button{ min-width: 181px !important; }
        }
    }
}

@media (min-width: 730px) {
    .linksBar { display: flex !important; }
}

@media (min-width: 768px) {
    .container { width: 750px; }
}

@media (min-width: 992px) {
    .container { width: 970px; }
}

@media (min-width: 1200px) {
    .container { width: 1200px; }
}

@media (min-width: 1700px){
    .empresas-nova .panel .panelBody .multInput.txCred{
        & div{ flex-grow: 1 !important; margin-right: 20px; 
            &:nth-child(4){ margin-right: 0; }
        }
    }
}

@media (max-height: 720px){
    .login .contentLogin{
        & .logo{ margin-top: -63px; }
        & .acessaForm {
            & h2{ margin-top: -20px; margin-bottom: 30px; }
            & .btn.entrar{ margin-top: 15px; }
        }
    }
}

