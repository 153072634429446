.login { width: 100vw; height: 100vh; display: flex; justify-content: center;
    & .contentLogin{ display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center;
        & .acessaForm{ margin-top: 60px;
            & h2{ margin-bottom: 60px; }
            & div{ display: flex; flex-direction: column; margin-bottom: 20px;
                & label{ color: #888888; font-size: 16px; font-weight: bold; text-align: left; }
                & input{ border: none; border-bottom: 1px solid #EEEEEE; font-size: 16px; padding-bottom: 9px; margin-top: 18px; 
                    &::placeholder{ color: #A4A4A4; }
                }
            }
            & div:nth-child(3){ margin-bottom: 16px; }
            & a.esqueciSenha{ text-decoration: none; color: #777; font-size: 14px; float: right; }
            & .btn.entrar{ padding: 13.5px 0; margin-top: 20px; margin-bottom: 26px; color: #fff; width: 300px; font-size: 20px; font-weight: bold; }
            & p{ color: #333; text-align: center; 
                & a { text-decoration: none; font-weight: 500; }
            }
        }
        & .divulgacao{  display: flex;  position: absolute; bottom: 24px;
            & div > p{ font-size: 12px; color: #999; font-weight: 500; }
            & div > img{ margin-right: 28px; }
            & .licenciador{ margin-left: 50px; }
        }
    }
}

.dashboard{ margin: 44px 40px 70px; 
    & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
    & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; margin-bottom: 23px; }
    & .panel{
        & .header{ display: flex; align-items: center;
            & .filtros{ margin-bottom: 32px;
                & button{ border: 1px solid #06014A; color: #06014A; font-size: 15px; width: 120px; border-radius: 20px; margin-right: 20px; background-color: transparent; }
                & button.active{ background-color: #06014A; color: #FFFFFF; }
            }
            & .faturamento{ margin-left: auto; text-align: right;
                p{ margin-top: 0; margin-bottom: 0; }
                h3{ margin-top: 0; font-size: 24px; font-weight: bold; }
            }
        }
    }
    & .multiGraph{ display: flex; 
        & .panel{ display: flex; flex-direction: column; width: 50vw;
            & p.titulo{ font-size: 16px; font-weight: bold; }
            & canvas{ margin-top: auto; }
        }
        & .panel.mr{ margin-right: 60px; }
    }
}

.empresas{ margin: 44px 40px 70px; 
    & .tituloButton{ display: flex; align-items: center; margin-bottom: 23px;
        & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
        & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; }
        & button{ margin-left: auto; border: 1px solid #06014A; width: 175px; font-weight: 500; font-size: 16px; }
    }
    & .detalhes{
        & .panel{ margin-bottom: 22px; }
        & .info{ display: flex; align-items: center; margin-left: 29px;
            & p{ font-size: 16px; color: #4F4F4F; }
            & .paginacao{ margin-left: auto; }
        }
    }
}

.empresas-nova, .empresas-usuarios-novo{ margin: 44px 40px 70px; 
    & .tituloButton{ display: flex; align-items: center; margin-bottom: 23px;
        & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
        & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; }
        & button{ margin-left: auto; border: 1px solid #06014A; width: 175px; font-weight: 500; font-size: 16px; }
    }
    & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
    & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; margin-bottom: 23px; }
    & .panel{
        & .panelBody{
            &.menus{
                & .menu{ border-bottom: 1px solid #DFDFDF; padding: 10px 0 10px 10px; 
                    &.filho{ border-top: 1px solid #DFDFDF; border-bottom: 0; margin-top: 10px; padding-bottom: 0; }
                }
                & .border{ border-bottom: 1px solid #DFDFDF; }
                & label{ display: flex; align-items: center; font-size: 14px; color: #4F4F4F }
                & input{ margin-right: 9px; margin-left: 0; }
            }
            & .multInput{ display: flex; flex-wrap: wrap; justify-content: space-between;
                & label{ color: #4F4F4F; font-size: 14px; margin-bottom: 3px; }
                & input{ padding: 9.5px 0; margin-bottom: 27px; margin-right: 27px; }
                & .btnInput{ margin-right: 27px; min-width: 260px !important; }
                &.txCred input{ margin-right: 0; }
            }
            // & .marginInputs{ margin-bottom: 27px; }
        }
    }
    & .acoes{ display: flex; margin-top: 12px; 
        & button.btn.voltar, a{ border: 1px solid #BDBDBD; color: #999999; font-weight: 500; font-size: 16px; width: 110px; }
        & button.btnCadastrar{ width: 175px; font-weight: 500; font-size: 16px; text-transform: uppercase; padding: 13px 0; margin-left: auto; }
    }
}

.empresas-usuarios{ margin: 44px 40px 70px; 
    & .tituloButton{ display: flex; align-items: center; margin-bottom: 23px;
        & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
        & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; }
        & button{ margin-left: auto; border: 1px solid #06014A; width: 175px; font-weight: 500; font-size: 16px; }
    }
    & .acoes{ margin-top: 12px; 
        & a{ border: 1px solid #BDBDBD; color: #999999; font-weight: 500; font-size: 16px; width: 110px; display: block; }
    }
}

.clientes{ margin: 44px 40px 70px; 
    & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
    & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; margin-bottom: 23px; }
    & .filtros{
        & .panel{ display: flex;
            & input { margin-right: 40px; height: 44px; }
            & select{ margin-right: 40px; width: 150px; }
            & button{ margin-top: 18px; width: 175px; font-weight: 500; font-size: 16px; text-transform: uppercase; }
        }
    }
    & .detalhes{
        & .panel{ margin-bottom: 22px; }
        & .info{ display: flex; align-items: center; margin-left: 29px;
            & p{ font-size: 16px; color: #4F4F4F; }
            & .paginacao{ margin-left: auto; }
        }
    }
}

.cobrancas{  margin: 44px 40px 70px; 
    & .tituloButton{ display: flex; align-items: center; margin-bottom: 23px;
        & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
        & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; }
        & button{ margin-left: auto; border: 1px solid #06014A; width: 175px; font-weight: 500; font-size: 16px; }
    }
    & .panel {
        &.pagto{
            & .table tbody tr:last-child {
                & td{ border-bottom: none; padding-top: 24px; 
                    &:nth-child(1){ font-weight: 500; font-size: 16px; color: #4F4F4F; }
                    &:nth-child(6){ font-weight: bold; font-size: 18px; color: #06014A; }
                    &:nth-child(7){ visibility: hidden; }
                }
            }
        }
    }
    & .filtros{
        & .panel{ display: flex;
            & input { margin-right: 40px; height: 44px; }
            & select{ margin-right: 40px; width: 150px; }
            & button{ margin-top: 18px; width: 175px; font-weight: 500; font-size: 16px; text-transform: uppercase; }
        }
    }
    & .detalhes{
        & .panel{ margin-bottom: 22px; }
        & .info{ display: flex; align-items: center; margin-left: 29px;
            & p{ font-size: 16px; color: #4F4F4F; }
            & .paginacao{ margin-left: auto; }
        }
    }
    .btn.btnVoltar{ border: 1px solid #BDBDBD; box-sizing: border-box; border-radius: 4px; color: #BDBDBD; width: 110px; 
        &:hover{ background-color: #BDBDBD; color: #F8F9FB; }
    }
    &.detalhes{
        & .panel .panelBody { display: flex; flex-direction: row; flex-wrap: wrap;
            & div{ margin-right: 80px; 
                & p:first-child{ font-size: 14px; letter-spacing: -0.33; color: #4F4F4F; margin-bottom: 5px; }
                & p:last-child{ font-size: 18px; letter-spacing: -0.33; color: #4F4F4F; margin-top: 0; }
                & p.pendente{ font-size: 18px; font-weight: bold; letter-spacing: -0.33px; }
                & p.pago{ font-size: 18px; font-weight: bold; letter-spacing: -0.33px; color: #27AE60; }
            }
        }
    }
}

.debitos{ margin: 44px 40px 80px; 
    & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
    & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; margin-bottom: 23px; }
    & .filtros{
        & .panel{ display: flex;
            & select{ margin-right: 40px; width: 200px; }
            & button{ margin-top: 18px; width: 175px; font-weight: 500; font-size: 16px; text-transform: uppercase; }
        }
    }
    & .panel.dbts .table{
        & tbody tr:last-child{
            & td{ padding-top: 14px; border-bottom: none;
                &:nth-child(1){ font-size: 15px; color: #4F4F4F; text-align: left !important; text-indent: 8px; }
                &:nth-child(3),&:nth-child(4){ color: var(--baseColor); font-size: 18px; font-weight: bold; }
            }
        }
    }
    & .info{ display: flex; align-items: center; margin-left: 29px;
        & p.totais{ font-size: 15px; color: #4F4F4F; }
        & p.valores{ font-size: 18px; font-weight: bold; margin-left: auto; text-align: right; 
            &:nth-child(2){ margin-right: -326px; }
            &:nth-child(3){ margin-right: 18px; }
        }
    }
}

.pagamentos{ margin: 44px 40px 70px; 
    & .tituloButton{ display: flex; align-items: center; margin-bottom: 23px;
        & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
        & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; }
        & button{ margin-left: auto; border: 1px solid #06014A; width: 175px; font-weight: 500; font-size: 16px; }
    }
    & .filtros{
        & .panel{ display: flex; flex-wrap: wrap;
            & input{ width: 140px; padding: 10.5px; }
            & p{ margin: auto 15px 0; font-size: 22px; font-weight: 300; }
            & button{ margin-top: 18px; width: 145px; font-weight: 500; font-size: 16px; text-transform: uppercase; }
        }
    }
    & .info{ display: flex; align-items: center; margin-left: 20px;
        & p.totais{ font-size: 15px; color: #4F4F4F; font-weight: 500; }
        & p.valores{ font-size: 18px; font-weight: bold; margin-left: auto; text-align: right; margin-right: 18px; }
    }
    & .infoPag{ display: flex; align-items: center; margin-left: 29px;
        & p{ font-size: 16px; color: #4F4F4F; }
        & .paginacao{ margin-left: auto; }
    }
}

.liquida-boleto{ margin: 44px 40px 80px; 
    & .tituloButton{ display: flex; align-items: center; margin-bottom: 23px;
        & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
        & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; }
        & button{ margin-left: auto; border: 1px solid #06014A; width: 175px; font-weight: 500; font-size: 16px; }
    }
    & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
    & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; margin-bottom: 23px; }
    & .filtros{
        & .panel{ display: flex;
            & select{ margin-right: 40px; width: 200px; }
            & button{ margin-top: 18px; width: 175px; font-weight: 500; font-size: 16px; text-transform: uppercase; }
        }
    }
    & .conteudo{
        & .inLine{ display: flex; flex-direction: row; align-items: center; 
            & .label{ font-size: 16px; color: #4F4F4F; margin-right: 8px; }
            & .value{ font-size: 18px; font-weight: bold; color: #06014A; }
            & .title{ font-weight: 500; font-size: 15px; color: #4F4F4F; }
            & .valor{ font-size: 18px; color: #4F4F4F; }
        }
        & .panel:not(.liqAutomatico) .panelBody {
            & .table tbody td{ border-bottom: none; }
            & input{ padding: 9.5px 0; margin-right: 27px; }
            & .btn{ background-color: #06014A; color: #FFF; margin-top: 19px; width: 175px; height: 45px; }
        }
    }
}