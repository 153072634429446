@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

*, body > * { font-family: 'Roboto' !important; }
body { background-color: #F8F9FB; padding: 0; margin: 0; }
a{ text-decoration: none; }
button{ outline: none; cursor: pointer; box-sizing: border-box; border: none; background-color: transparent; }
button.btn,a.btn{ padding: 10px 0; font-size: 16px; border-radius: 4px; transition: ease-in-out .3s; text-align: center; }
select, input{ outline: none; }
select{ cursor: pointer; border-color: #DFDFDF; height: 44px; background-color: #FFFFFF; font-size: 16px; padding-left: 10px; border-radius: 2px; }
input{ background: #FFFFFF; color: #4F4F4F; border: 1px solid #DFDFDF; box-sizing: border-box; border-radius: 2px; font-size: 20px; padding-left: 10px !important; 
    &.disabled{ background-color: rgba(84, 84, 84, 0.1); border-color: rgba(118, 118, 118, 0.3); }
}
.container { padding-right: 15px; padding-left: 15px; margin-right: auto; margin-left: auto; }

// CUSTOM
.bg-roxo{ background-color: #06014A !important; }
.cl-roxo{ color: #06014A !important; }
.cl-branco{ color: #FFFFFF; }
.bd-roxo{ border-color: #06014A !important; }
:root{
    --baseColor: #06014A;
}
label{ color: #4F4F4F; }
.body{ margin-left: 240px; }

.sideMenuBar{ width: 240px; height: 100%; position: fixed; left: 0;
    & .profile{ margin-top: 50px; margin-left: 35px;
        & img{ border-radius: 50%; }
        & p{ font-size: 16px; font-weight: bold; margin-top: 14px; color: #FFFFFF; }
    }
    & .navigation{ 
        & ul{ list-style: none; padding: 0; margin: 25px 25px 0 25px;
            & a{ color: #FFFFFF; display: flex; padding: 13px 0 13px 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.3); font-weight: 500; 
                font-size: 16px; align-items: center;
                & img{ margin-right: 15px; }
                &:hover, &:focus{ background-color: rgba(255, 255, 255, 0.1); border-radius: 4px; }
            }
            & a.active{ background-color: rgba(255, 255, 255, 0.3); border-bottom: none; border-radius: 4px; }
            & a.noBorder{ border-bottom: none; }
            
        }
    }
}

.navbar { display: flex; align-items: center; background-color: #FFFFFF; padding: 2px 0; box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
    & .barLogo { margin-left: 33px;
        & img { width: 103px; }
    }
    & .barLinks{ margin-left: auto; margin-right: 33px;
        & ul{ list-style: none; padding: 0;
            & a{ display: flex; align-items: center; color: #4F4F4F; font-size: 14px; font-weight: bold;
                & img{ margin-left: 7px; }
            }
        }
    }
}

.footer { flex: 0; text-align: center; background-color: #EEEEEE; padding: 2.5px 20px; position: fixed; width: 100%; bottom: 0;
    & p { font-size: 12px; color: #999999; }
}

.paginacao{
    & button{ font-size: 18px; color: #4F4F4F; margin-left: 11px; padding: 10.5px 16.6px; font-weight: bold; border: 1px solid #4F4F4F; 
        box-sizing: border-box; border-radius: 5px; transition: .2s; box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04);
    }
    & button.disabled, button.disabled:hover{ color: #999; border: 1px solid #999999; cursor: default; }
    & button.active, button:hover{ color: #06014A; border: 1px solid #06014A; }
}

.table { border-collapse: collapse; width: 100%;
    & td, th{ border-bottom: 1px solid #DFDFDF; padding: 11px 20px; }
    & td{ border-bottom: 1px solid #DFDFDF; font-size: 15px; color: #4F4F4F; 
        &.tag{
            & span{ padding: 3px 8px; border-radius: 4px; display: block; width: 97px; font-weight: bold; font-size: 12px; text-align: center; }
        }
    }
    & th{ font-size: 15px; color: #E49803; text-align: left; }
}

input[type="checkbox"].checkBoxtable{
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    border: 1px solid #999999;
    border-radius: 2px;
    position: relative;
    width: 17px;
    height: 17px;
    transition: all .1s linear;
    padding-left: 0 !important;

    &:checked{ background-color: #06014A; }
    &:focus{ outline: 0 none; box-shadow: none; }
}

input[type="file"]{
    display: none;
}

.panel{ background-color: #FFFFFF; padding: 24px 30px 27px 29px; margin-bottom: 30px; box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04); border-radius: 5px; 
    & .panelHeader{  border-bottom: 1px solid #DFDFDF; margin-bottom: 18.5px;
        & h3{ color: #E49803; font-size: 16px; font-weight: bold; margin: 0; padding-left: 4px; padding-bottom: 7px; }
    }
}

.carrega-mais{ display: flex; align-items: center; justify-content: center; 
    & button { font-size: 30px; border: 2px solid #8e8e8e; color: #8e8e8e; border-radius: 50%; width: 60px; height: 60px; }
}

.cliModal{
    & h2, & h3{ color: #06014A; }
}

.ck.ck-editor__main>.ck-editor__editable{ max-height: 300px !important; }